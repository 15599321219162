import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";
class UnitTypeService{
    editUnitType(formData,id){
        const url = `api/unit/type/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    getFilteredUnitTypeLists(param){
        const url = `api/unit/types/filter`
        const params = {
            'page-index':param.size,
            'offset':param.offset,
            'searched':param.searched,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    createNewUnitType(formData){
        const url = `api/unit/type/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    deleteUnitType(id){
        const url = `api/unit/type/${id}`;
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new UnitTypeService();