<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newUnitType' || modalId=='editUnitType'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
             {{(modalId=="newUnitType")?'Add New Unit':'Edit Unit'}}
            </h5>
            <a class="close" @click="$store.dispatch('modalClose')" title="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12">
                <div class="group">
                  <label class="group-label">Basic Information</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Title *</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Unit Title"
                          v-model="formData.title"
                          ref="unitType"
                              required
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 mr-0 pr-0">Status *</div>
                      <div class="col-md-7 input-group ml-0 pl-0">
                        <select class="form-control" v-model="formData.status" required>
                          <option value="1">Active</option>
                          <option value="0">In-Active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      formData: {
        id: "",
        title: "",
        status: 1,
      },
      disableSubmitButton:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("unitTypes",["unitTypeLists"])
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        this.disableSubmitButton = true;
        Services.createNewUnitType(this.formData).then(res=>{
              this.disableSubmitButton =false;
              this.setNotification(res.data.success_message);
              this.$store.dispatch("modalClose");
              this.$emit('parent-event');
              this.loading = false;
        }).catch(err=>{
          this.disableSubmitButton = false;
            this.error=false;
            this.loading = false;
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
      } else if (this.modalMode == "edit") {
        this.disableSubmitButton = true;
        // this.$store.commit("setApiUrl", `api/item/group/${this.formData.id}`);
        // this.$store.commit("updateData", this.formData);
        Services.editUnitType(this.formData,this.formData.id).then(res=>{
          this.disableSubmitButton = false;
              this.$emit('parent-event');
              this.$store.dispatch('modalClose');
              this.setNotification(res.data.success_message);
              this.loading = false;
        }).catch(err=>{
          this.disableSubmitButton = false;
            this.error=false;
            this.loading = true;
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    clearData() {
      this.formData.id = "";
      this.formData.title = "";
      this.formData.status = 1;
    },
  },
  watch: {
    modalId(value) {
      if(value == "editUnitType"){
        this.$nextTick(() => this.$refs.unitType.focus());
        let id = this.dataId;
        let details = this.unitTypeLists.find(function (data) {
          return data.id == id;
        });
        this.formData = {...details};
      }
      if(value == 'newUnitType'){
          this.$nextTick(() => this.$refs.unitType.focus());
          this.clearData();
      }
      
    },
  },
};
</script>
