<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Item</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Unit
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Unit</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          v-if="checkSinglePermission('create_item_unit')"
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newUnitType',
              mode: 'create',
            })
          "
        >
          New Unit
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="filter.size" @change="searchFilter" class="mg-t-5 mr-2">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input type="text" v-model="params.searched"  class="float-right" placeholder="Search..." @keyup="searchFilter">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-25p">Unit Title</th>
              <th class="wd-10p">Status</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_item_unit','delete_item_unit','edit_item_unit'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && unitTypeLists.length > 0">
            <tr v-for="(group, index) in unitTypeLists" :key="index">
              <th scope="row" class="table-start-group">{{ pageSerialNo+index }}.</th>
              <td>{{ group.title }}</td>
              <td><span v-if="group.status == 1">Active</span><span v-else>In-active</span></td>
              <td class="text-center table-end-item" v-if="checkIfAnyPermission(['view_item_unit','delete_item_unit','edit_item_unit'])">
                <!-- <a href="javascript:;" class="mr-2">
                  <i class="fas fa-receipt"></i>
                </a>
                <a href="javascript:;" class="mr-2">
                  <i class="fas fa-eye tx-success"></i>
                </a> -->
                <a
                  href="javascript:;"
                  title="Edit"
                  class="mr-2"
                  v-if="checkSinglePermission('edit_item_unit')"
                  @click="edit('editUnitType', group.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" v-if="checkSinglePermission('delete_item_unit')" title="Delete" @click="drop(group.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && unitTypeLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td  colspan="11" style="text-align:center;">
                {{error_message}}
              </td>
            </tr>
          </tbody>
        </table>
        <Paginate :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <Fields @parent-event="getData()" />
      </div>
    </div>
  </div>
</template>
<script>
import Fields from "./fields";
import { mapGetters } from "vuex";
import {Search} from "../../../../mixins/search";
import Paginate from 'vuejs-paginate';
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  mixins: [Search],
  components: {
    Fields, Paginate
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("unitTypes",["unitTypeLists"])
  },
  data(){
    return{
      loading:false,
      error:false,
      error_message:'',
      params:{
        size:10,
        searched:'',
        offset:0,
      },
      totalPageCount:0,
      pageSerialNo:1,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$swal({
       title: 'Do you really want to delete Unit Type ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
          // delete lead
           return  Services.deleteUnitType(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
             this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    getData(){
      this.loading = true;
      //this.$store.commit("getData", `api/unit/types/size/${this.filter.size}`);
       Services.getFilteredUnitTypeLists(this.params).then(res=>{
        this.$store.commit("unitTypes/setUnitTypeLists",res.data.data.data);
        this.totalPageCount = res.data.data.total_no_of_pages;
        this.loading = false;
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
          this.error_message = "Access Denied !!!";
        } else {
          this.error_message = "Error Fetching data from the server.";
        }
        this.loading = false;
        this.error = true; 
        console.log(err);
      });
    },
    searchFilter: _.debounce(function(){
      this.loading = true;
      Services.getFilteredUnitTypeLists(this.params).then(res=>{
          this.$store.commit("unitTypes/setUnitTypeLists",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
          this.error_message = "Access Denied !!!";
        } else {
          this.error_message = "Error Fetching data from the server.";
        }
          this.loading = true;
          this.error =true;
          console.log(err);
      });
    },1000),
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  watch: {
    // eventMessage(value) {
    //   if (value.indexOf("deleted success") >= 0) {
    //     this.$store.commit("setRemoveList", this.dataId);
    //   }
    // },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>